import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const LogoDisplay = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 4,
            }}
        >
            <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/1555.svg`}
                alt="Logo 1"
                sx={{
                    width: '75%',
                    mb: isMobile ? 2 : 0,
                    mr: isMobile ? 0 : 2,
                }}
            />
        </Box>
    );
};

export default LogoDisplay;

import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: 'https://ath-pubweb.cosmote-evalue.gr',
});

// Add a request interceptor
axiosInstance.interceptors.request.use((config) => {
    config.headers['Accept'] = 'application/json, text/javascript, */*; q=0.01';
    config.headers['Content-Type'] = 'application/json';
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance;

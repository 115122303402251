import React, { useState } from 'react';
import { Box, TextField, Typography, Button } from '@mui/material';
import axiosInstance from './axiosConfig';

const VideoCallButton = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();

        const validUsername = 'cosmote';
        const validPassword = 'cosmote1234deth';

        if (username === validUsername && password === validPassword) {
            setError('');
            await handleVideoCall();
        } else {
            setError('Λάθος Username ή Password');
        }
    };

    const handleVideoCall = async () => {
        const projectToken = '9ea68c23-dae3-4bae-86b0-e6852c856323';
        const videoCallServiceUID = 'd7bb42ab-193d-4fda-ab14-4cceced62718';
        const videoCallServiceCallData = [];

        // Form data
        const formData = new URLSearchParams();
        formData.append('CustomerToken', projectToken);
        formData.append('ServiceUID', videoCallServiceUID);
        formData.append('CallData', JSON.stringify(videoCallServiceCallData));

        try {
            const response = await axiosInstance.post('/CLS/Customer/InitVideoCall', formData);

            const uid = response.data;
            console.log('VideoCall: ' + uid);

            if (uid) {
                const isMobile = window.innerWidth <= 768;
                if (isMobile) {
                    window.location.href = `https://ath-pubweb.cosmote-evalue.gr/CLS/CustomerPages/Video.html?id=${uid}`;
                } else {
                    const width = window.screen.availWidth * 0.8;
                    const height = window.screen.availHeight * 0.8;
                    const left = (window.screen.availWidth - width) / 2;
                    const top = (window.screen.availHeight - height) / 2;
                    window.open(
                        `https://ath-pubweb.cosmote-evalue.gr/CLS/CustomerPages/Video.html?id=${uid}`,
                        "_blank",
                        `height=${height},width=${width},left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no`
                    );
                }
            }

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }}
            onSubmit={handleLogin}
        >
            <Typography variant="h6" gutterBottom>
                Login
            </Typography>
            <TextField
                label="Username"
                variant="outlined"
                margin="normal"
                fullWidth
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="Password"
                type="password"
                variant="outlined"
                margin="normal"
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputLabelProps={{ shrink: true }}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                Είσοδος
            </Button>
        </Box>
    );
};

export default VideoCallButton;

import React, { useEffect, useState } from 'react';
import { Box, Card } from '@mui/material';
import LogoDisplay from './main/LogoDisplay';
import VideoCallButton from './main/VideoCallButton';
import disableDevtool from 'disable-devtool';

const App = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (window.location.hostname !== 'localhost') {
            disableDevtool({
                clearIntervalWhenDevOpenTrigger: true,
                clearConsoleWhenDevOpenTrigger: true,
                interval: 2000,
                randomizeInterval: 500,
                disableMenu: true,
                disableConsoleOutput: true,
            });
        }
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff',
                backgroundColor: '#003476',
                height: '95vh',
                padding: 2,
                overflow: 'auto',
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: { xs: 2, sm: 4 },
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    boxShadow: 3,
                    width: '100%',
                    maxWidth: { xs: '100%', sm: '400px' },
                    height: 'auto',
                    maxHeight: '100%',
                    overflow: 'auto',
                }}
            >
                <LogoDisplay />
                <VideoCallButton
                    username={username}
                    setUsername={setUsername}
                    password={password}
                    setPassword={setPassword}
                    error={error}
                    setError={setError}
                />
            </Card>
        </Box>
    );
};

export default App;
